import React, { useState } from 'react'
import logo from '../images/logo.png';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logoArab from '../images/logoArab.png';
import logoFrench from '../images/logoFrench.png';
import logoEnglish from '../images/logoEnglish.png';
import LanguageDropDown from '../components/LanguageDropDown';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react'

function Navbar() {
    const { t } = useTranslation()
    const [services, setServices] = useState(false)

    const [dropdown, setDropDown] = useState(false)
    const location = useLocation();

    const savedLanguage = localStorage.getItem('i18nextLng') || process.env.REACT_APP_DEFAULT_LANGUAGE;
    const language = process.env.REACT_APP_LANGUAGE_SWITCH;



    const getImage = () => {
        if (savedLanguage == 'ar') {
            return logoArab
        } else if (savedLanguage == 'fr') {
            return logoFrench
        } else if (savedLanguage == 'en' && language === 'arabic') {
            return logoArab

        } else if (savedLanguage == 'en' && language !== 'arabic') {
            return logoEnglish
        }
    }


    return (
        <div className='flex  xl:justify-center justify-between absolute top-0 z-40 w-screen items-center 2xl:px-[4rem] xl:px-[2rem] md:px-12 px-6 md:h-[6.8rem] h-[4rem]'>
            <div className='md:flex hidden justify-start items-center w-1/3 '>
                <Link to='/'><img src={getImage()} alt='logo' objectFit="cover" className='w-48' /></Link>

                {/* <h1 className='text-secondary font-[900] text-[30px]'>Logo.</h1> */}
            </div>

            <div className="text-secondary md:hidden  w-full flex justify-between items-center">
                 {
                    language === 'arabic' &&
                    <LanguageDropDown />

                }
                <Link to='/'><img src={getImage()} alt='logo' objectFit="cover" className='w-44' /></Link>

                <button onClick={() => setDropDown(!dropdown)} className="focus:outline-none z-50">
                    {dropdown ? (
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6 cursor-pointer">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                        </svg>
                    ) : (
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="size-6 cursor-pointer">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                        </svg>
                    )}
                </button>

                {dropdown && (
                    <div
                        className={`absolute top-[4rem] right-0 flex-col flex w-screen justify-start items-center z-50 bg-white duration-300 ease-in-out transform ${dropdown ? 'translate-y-0 opacity-100' : '-translate-y-10 opacity-0'
                            }`}
                    >
                        <Link
                            onClick={() => setDropDown(!dropdown)}
                            to="/"
                            className="text-primary scroll-link cursor-pointer font-[700] transition-all px-3 duration-100 hover:border-b-[1.5px] w-full hover:text-gray-100 hover:bg-[#3B3B3B] py-4 hover:border-gray-100 leading-[20px] uppercase text-[14px]"
                        >
                            {t("Home")}
                        </Link>
                        <a
                            onClick={() => setServices(!services)}
                            className="text-primary flex justify-start items-center gap-3 scroll-link cursor-pointer font-[700] transition-all px-3 duration-100 hover:border-b-[1.5px] w-full hover:text-gray-100 hover:bg-[#3B3B3B] py-4 hover:border-gray-100 leading-[20px] uppercase text-[14px]"
                        >
                           <span> {t("Services")}</span>

                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className={`size-4 transition-all duration-300 ${services ? 'rotate-180' : ''}`}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                            </svg>


                        </a>

                        {services &&

                            <div className='flex justify-start items-center transition-all duration-300 w-full flex-col px-4'>
                                <Link
                                    onClick={() => setDropDown(!dropdown)}
                                    to="/link-building"
                                    className="text-primary scroll-link cursor-pointer bg-gray-50 font-[700] transition-all px-3 duration-100 hover:border-b-[1.5px] w-full hover:text-gray-100 hover:bg-[#3B3B3B] py-4 hover:border-gray-100 leading-[20px] uppercase text-[14px]"
                                >
                                    {t("LinkBuilding")}
                                </Link>
                                <Link
                                    onClick={() => setDropDown(!dropdown)}
                                    to="/guest-blogging"
                                    className="text-primary scroll-link cursor-pointer bg-gray-50 font-[700] transition-all px-3 duration-100 hover:border-b-[1.5px] w-full hover:text-gray-100 hover:bg-[#3B3B3B] py-4 hover:border-gray-100 leading-[20px] uppercase text-[14px]"
                                >
                                    {t("Guest Blogging")}
                                </Link>
                                <Link
                                    onClick={() => setDropDown(!dropdown)}
                                    to="/content-writing"
                                    className="text-primary scroll-link cursor-pointer bg-gray-50 font-[700] transition-all px-3 duration-100 hover:border-b-[1.5px] w-full hover:text-gray-100 hover:bg-[#3B3B3B] py-4 hover:border-gray-100 leading-[20px] uppercase text-[14px]"
                                >
                                    {t("Content Writing")}
                                </Link>
                                <Link
                                    onClick={() => setDropDown(!dropdown)}
                                    to="/Audit"
                                    className="text-primary scroll-link cursor-pointer bg-gray-50 font-[700] transition-all px-3 duration-100 hover:border-b-[1.5px] w-full hover:text-gray-100 hover:bg-[#3B3B3B] py-4 hover:border-gray-100 leading-[20px] uppercase text-[14px]"
                                >
                                    {t("SEO Audit Mastery")}
                                </Link>


                            </div>

                        }
                        <Link
                            onClick={() => setDropDown(!dropdown)}
                            to="/Contact"
                            className="text-primary scroll-link cursor-pointer font-[700] transition-all px-3 duration-100 hover:border-b-[1.5px] w-full hover:text-gray-100 hover:bg-[#3B3B3B] py-4 hover:border-gray-100 leading-[20px] uppercase text-[14px]"
                        >
                            {t("Contact")}
                        </Link>

                        <Link
                            onClick={() => setDropDown(!dropdown)}
                            to="/Blog"
                            className="text-primary scroll-link cursor-pointer font-[700] transition-all px-3 duration-100 hover:border-b-[1.5px] w-full hover:text-gray-100 hover:bg-[#3B3B3B] py-4 hover:border-gray-100 leading-[20px] uppercase text-[14px]"
                        >
                            {t("Blog's")}
                        </Link>
                    </div>
                )}

               
            </div>


            <ul className='md:flex hidden 2xl:justify-center xl:justify-center md:justify-end gap-12 w-2/3  cursor-pointer items-center text-gray-300   font-[600] text-[16px] capitalize'>
                <li className={`hover:text-secondary  ${location.pathname == '/' ? 'border-b-[2px] text-secondary border-secondary' : ''}`}><Link to='/'>{t("Home")}</Link></li>
                <li className={`hover:text-secondary ${location.pathname == '/Services' ? 'border-b-[2px] text-secondary border-secondary' : ''}`}><Popover>
                    <PopoverButton className={`hover:text-secondary outline-none ${location.pathname == '/guest-blogging' || location.pathname == '/content-writing' || location.pathname == '/link-building' ? 'border-b-[2px] text-secondary border-secondary' : ''}`}>
                        {t("Services")}
                    </PopoverButton>
                    <PopoverPanel
                        transition
                        anchor="bottom"
                        className="divide-y z-50 divide-white/5 rounded-xl bg-primary mt-2 text-sm/6 transition duration-200 ease-in-out [--anchor-gap:var(--spacing-5)] data-[closed]:-translate-y-1 data-[closed]:opacity-0"
                    >
                        <div className="p-3">
                            <Link to='/link-building' className="block rounded-lg py-2 px-3 transition hover:bg-white/5">
                                <p className="font-semibold text-white">{t('LinkBuilding')}</p>
                                <p className="text-white/50">{t('Power up your SEO with quality links.')}</p>
                            </Link>

                            <Link to='/guest-blogging' className="block rounded-lg py-2 px-3 transition hover:bg-white/5">
                                <p className="font-semibold text-white">{t('Guest Blogging')}</p>
                                <p className="text-white/50">{t('Amplify your reach with guest posts.')}</p>
                            </Link>

                            <Link to='/content-writing' className="block rounded-lg py-2 px-3 transition hover:bg-white/5">
                                <p className="font-semibold text-white">{t('Content Writing')}</p>
                                <p className="text-white/50">{t('Craft content that ranks and engages.')}</p>
                            </Link>
                              <Link to='/Audit' className="block rounded-lg py-2 px-3 transition hover:bg-white/5">
                                <p className="font-semibold text-white">{t('SEO Audit Mastery')}</p>
                                <p className="text-white/50">{t('Uncover. Optimize. Dominate.')}</p>
                            </Link>


                        </div>

                    </PopoverPanel>
                </Popover></li>
                <li className={`hover:text-secondary ${location.pathname == '/Contact' ? 'border-b-[2px] text-secondary border-secondary' : ''}`}><Link to='/Contact'>{t("Contact Us")}</Link></li>
                <li className={`hover:text-secondary ${location.pathname == '/Blog' ? 'border-b-[2px] text-secondary border-secondary' : ''}`}><Link to='/Blog'>{t("Blog's")}</Link></li>
            </ul>

            <div className='xl:flex hidden justify-end items-center gap-3 w-1/3'>
                {
                    language === 'arabic' &&
                    <LanguageDropDown />

                }



                <a href={process.env.REACT_APP_DACHBOARD_LINK} className='flex justify-center items-center gap-2 capitalize border-secondary border px-4 py-2 rounded-full bg-secondary/10 text-secondary  font-[400] text-[14px] w-fit '>
                    <span>{t("Dashboard")}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-arrow-up-right"><path d="M7 7h10v10" /><path d="M7 17 17 7" /></svg>
                </a>


            </div>


        </div>

    )
}

export default Navbar