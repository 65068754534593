import React from 'react';
import photo from '../images/photo.jpg';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Hero() {
    const { t } = useTranslation()

    return (
        <div className='w-full h-fit  relative flex justify-center items-center' >
            <div className="absolute inset-0 bg-primary z-10 opacity-60"></div>
            <div
                className="absolute inset-0 bg-cover rotate-180 bg-center"
                style={{ backgroundImage: `url(${photo})` }}
            ></div>




            {/* Content Section */}
            <div className='flex z-20 flex-col justify-center max-w-7xl  py-24 text-center mt-6  mx-auto px-3  mt-12 md:mt-24 items-center gap-6 '>
                <div className='flex  justify-center items-center gap-2 capitalize border-gray-300 border md:px-5 px-2 py-2.5 rounded-full bg-white/10 text-gray-300 font-[500] text-[10px] md:text-[12px] w-fit'>
                    <span>{t("Boost Your Influence with Smarter Links!")}</span>🔥
                </div>
                <h1 className='md:text-[48px] text-[30px]  font-[800] leading-[40px] md:leading-[80px] text-gray-100'>
                    {t("Boost Your Website Authority with Tailored")} <span className='text-secondary'>{t("Link-Building")}</span> {t("Strategies")}
                </h1>
                <p className='md:text-[18px] text-[14px] font-[400] leading-[25px] md:leading-[40px] text-gray-100'>
                    {t("Experience measurable results with high-quality backlinks and SEO that puts you ahead of the competition")}
                </p>
                <div className='flex justify-center items-center gap-3 w-full'>
                    <Link to='/Contact' className='flex justify-center items-center gap-2 capitalize border-gray-300 border md:px-5 px-2.5 py-2.5 rounded-full bg-white/10 text-gray-300  font-[400]   md:text-[16px] text-[14px] w-fit '>
                        <span>{t("Let’s Get Started")}</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-arrow-up-right"><path d="M7 7h10v10" /><path d="M7 17 17 7" /></svg>
                    </Link>
                    <a href={process.env.REACT_APP_DACHBOARD_LINK} className='flex justify-center items-center gap-2 capitalize border-primary border md:px-5 px-2.5 py-2.5 rounded-full bg-secondary text-primary font-[500]   md:text-[16px] text-[14px] w-fit'>
                        <span>{t("Dashboard")}</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-arrow-up-right">
                            <path d="M7 7h10v10" />
                            <path d="M7 17 17 7" />
                        </svg>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Hero;
