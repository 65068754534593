import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import Hero from '../components/Services/Hero';
import Features from '../components/Services/Features';
import Faq from '../components/Faq';
import photo from '../images/Audit.webp';
import { Link } from 'react-router-dom';
function Audit() {
    const { pathname } = useLocation();
    const { t } = useTranslation();

    // useEffect(() => {
    //     window.scrollTo({
    //         top: 0,
    //         behavior: 'smooth'
    //     });
    // }, [pathname]);

    const seoAuditFeatures = [
        {
            title: t("Comprehensive Website Analysis"),
            description: t("Our thorough SEO audit examines every aspect of your website, from technical SEO to content quality, providing a complete picture of your online presence."),
        },
        {
            title: t("Industry-Leading Tools"),
            description: t("We utilize top-tier SEO tools like Ahrefs and SEMrush to gather in-depth data and insights about your website's performance and competitive landscape."),
        },
        {
            title: t("Actionable Recommendations"),
            description: t("Receive a detailed report with prioritized, actionable steps to improve your website's search engine rankings and overall SEO health."),
        },
        {
            title: t("Competitor Analysis"),
            description: t("Gain valuable insights into your competitors' strategies, allowing you to identify opportunities and stay ahead in your industry."),
        }
    ];

    return (
        <div className="h-full w-full">
            <Helmet>
                <title>{t("metaTitle")}</title>
                <meta name="description" content={t("metaDescription")} />
            </Helmet>
            <Hero
                title={t("heroTitle")}
                description={t("heroDescription")}
                photo={photo}
            />
            <Features
                title={t("featuresTitle")}
                description={t("featuresDescription")}
                features={seoAuditFeatures}
            />

            <section className="w-full relative bg-gray-100 py-16 px-4 max-w-7xl lg:mx-auto px-4 mb-24  rounded-2xl">
            
                <div className=" mx-auto text-center z-20 space-y-8">
                    <h2 className="text-4xl md:text-5xl font-bold text-primary tracking-wider">
                        {t("ctaTitle")}
                    </h2>
                    <p className="text-neutral-700 text-lg md:text-xl max-w-2xl mx-auto">
                        {t("ctaDescription")}
                    </p>
                    <div className="flex justify-center">
                        <Link to='/Contact' className='flex justify-center items-center gap-2 capitalize border-gray-300 border md:px-5 px-2.5 py-2.5 rounded-full bg-primary hover:scale-105 text-gray-300  font-[400]   md:text-[16px] text-[14px] w-fit '>
                            <span>{t("ctaButton")}</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-arrow-up-right"><path d="M7 7h10v10" /><path d="M7 17 17 7" /></svg>
                        </Link>
                    </div>
                    <p className="text-neutral-500 text-sm">
                        {t("ctaFootnote")}
                    </p>
                </div>
            </section>


            <Faq />
        </div>
    )
}

export default Audit;

