import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

function Pricing({ title, description, pricings }) {
    const { t } = useTranslation()
    return (
        <section className="py-24 relative">
            <div className="absolute h-[36.5rem] w-full top-0 bg-footer -z-10" />
            <div className="mx-auto xl:px-24 lg:px-20  md:px-12 px-3">
                <div className="mb-12">
                    <h2 className="font-manrope md:text-5xl text-[28px] text-center font-bold text-white mb-4">{title}</h2>
                    <p className="text-gray-300 md:text-xl text-[16px] text-center leading-[35px]">{description}</p>
                </div>
                {/*Grid*/}
                <div className={`space-y-8 lg:grid 2xl:grid-cols-3  md:grid-cols-2 lg:auto-rows-max place-items-center sm:gap-6 xl:gap-8 lg:space-y-0 lg:items-center`}>
                    {/*Pricing Card*/}
                    {pricings?.map((pricing, index) => {
                        return (
                            <div key={index} className={`${pricing.popular ? 'border-secondary border-2' : ''}  group relative flex flex-col mx-auto w-full  bg-white rounded-2xl shadow-2xl transition-all duration-300  p-8   `}>
                                <p className={`text-primary ${pricing.popular ? '' : 'hidden'} w-fit absolute left-3 top-3 mx-auto text-sm font-[400] border-primary border rounded-full px-3 py-1 bg-secondary `} >Popular</p>

                                <div className="border-b border-solid border-gray-200 pb-9 mb-9">
                                    {/* <div className="w-16 h-16 rounded-full bg-indigo-50 mx-auto flex justify-center items-center transition-all duration-300 group-hover:bg-primary">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 text-primary transition-all duration-300 group-hover:text-white">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z" />
                                        </svg>



                                    </div> */}
                                    <div className="font-manrope flex items-center justify-center md:text-[35px]  text-[28px] gap-2 font-bold my-7 text-center text-primary"><h3>{pricing.plan}</h3>  </div>
                                    <div className="flex items-center gap-2 justify-center">
                                        <span className="font-manrope text-[25px] font-medium text-gray-900">{pricing.amount}</span>
                                        <span>|</span>
                                        <span className="text-xl text-gray-500 ml-3"> {pricing.duration}</span>
                                    </div>


                                </div>
                                {/*List*/}
                                <ul className="mb-12 space-y-6 text-left text-md text-gray-600 group-hover:text-gray-900">
                                    {pricing?.details?.map((detail, index) => {
                                        return (
                                            <li key={index} className="flex items-center gap-3">
                                                {/* Icon */}
                                                <span className="w-1.5 h-1.5  rounded-full bg-primary" />
                                                <span>{detail}</span>
                                            </li>
                                        )
                                    })
                                    }


                                </ul>
                                <div className='flex justify-center items-center gap-3 w-full'>
                                    <Link to='/Contact' className='flex justify-center items-center hover:scale-105 gap-2 capitalize border-gray-800 border md:px-5 px-2.5 py-2.5 rounded-full bg-white/10 text-gray-800  font-[400]   md:text-[16px] text-[14px] w-fit '>
                                        <span>{t("Contact Us")}</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-arrow-up-right"><path d="M7 7h10v10" /><path d="M7 17 17 7" /></svg>
                                    </Link>
                                    
                                </div>
                            </div>
                        )
                    })
                    }

                </div>
            </div>
        </section>

    )
}

export default Pricing