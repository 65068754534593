import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, BrowserRouter } from 'react-router-dom';

import Navbar from './layout/Navbar';
import Footer from './layout/Footer';

import Home from './pages/Home';
import Blog from './pages/Blog';
import Post from './pages/Post';
import Contact from './pages/Contact';
import i18n from './i18next';
import { I18nextProvider, useTranslation } from 'react-i18next';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import Linkbuilding from './pages/Linkbuilding';
import ContentCreation from './pages/ContentCreation';
import GuestBloging from './pages/GuestBloging';
import OtherServices from './pages/OtherServices';
import { Helmet } from 'react-helmet';
import Audit from './pages/Audit';

function App() {


  const { t } = useTranslation()

  useEffect(() => {
    const setInitialValues = async () => {
      if (process.env.REACT_APP_DEFAULT_LANGUAGE !== "ar") {
        localStorage.removeItem('i18nextLng');

      }
      const currentLanguage = localStorage.getItem('i18nextLng') || process.env.REACT_APP_DEFAULT_LANGUAGE;

      if (currentLanguage === 'ar') {
        document.body.style.direction = 'rtl';
        document.body.style.fontFamily = '"Tajawal", sans-serif';
      } else {
        document.body.style.direction = 'ltr';
        document.body.style.fontFamily = '"Montserrat", sans-serif';
      }
    };

    setInitialValues();
  }, []);


  return (
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>

        <Navbar />
        <Routes>
          <Route path="/" element={<>
            <Helmet>
              <title>{t("Meta Title Home")}</title>
              <meta name="description" content={t("Meta Description Home")} />
            </Helmet>
            <Home />
          </>} />
          <Route path="/Blog" element={<Blog />} />
          <Route path="/Post/:slug" element={<Post />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Terms-Conditions" element={<Terms />} />
          <Route path="/Privacy-Policy" element={<Privacy />} />
          <Route path="/guest-blogging" element={<GuestBloging />} />
          <Route path="/content-writing" element={<ContentCreation />} />
          <Route path="/link-building" element={<Linkbuilding />} />
          <Route path="/Audit" element={<Audit />} />

        </Routes>
        <Footer />
      </I18nextProvider >

    </BrowserRouter>
  );
}

export default App;
